type LogoProps = {
  className?: string;
};

const Logo = ({ className }: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 434.38 155.74"
    className={className}
  >
    <path
      d="M358.91 461.13v83a27.13 27.13 0 1 1-54.25 0V418.32h.87a45.21 45.21 0 0 0 53.38 42.81Z"
      fill="#fac51b"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M304.66 418.32v54.25h-4.11a27.13 27.13 0 0 1 0-54.25Z"
      fill="#f37935"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M466.59 417.5v125.76a27.13 27.13 0 1 1-54.25 0v-71.51h39.45V417.5Z"
      fill="#42a85f"
      transform="translate(-273.43 -417.5)"
    />
    <path d="M138.91 0h39.45v54.25h-39.45z" fill="#efefef" />
    <path
      d="M412.34 417.5v54.25h-4.11a27.13 27.13 0 1 1 0-54.25Z"
      fill="#f7da63"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M358.91 418.32v42.81a45.21 45.21 0 0 1-53.38-42.81Z"
      fill="#fba026"
      transform="translate(-273.43 -417.5)"
    />
    <circle cx="388.78" cy="108.5" r="45.6" fill="#28324e" />
    <path
      d="M589.39 525.15a45.48 45.48 0 0 1-16 37.19.52.52 0 0 1-.83-.57 42.76 42.76 0 0 0-37.17-57 42.6 42.6 0 0 0-35.6 14.92.46.46 0 0 1-.81-.37 45.61 45.61 0 0 1 90.37 5.87Z"
      fill="#d14840"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M580 457a36.16 36.16 0 0 1-68.28 16.64.42.42 0 0 1 .44-.61 35.08 35.08 0 0 0 6.9.38 36.42 36.42 0 0 0 34.63-36.81 35.94 35.94 0 0 0-3.5-14.86.2.2 0 0 1 .22-.29A36.14 36.14 0 0 1 580 457Z"
      fill="#eb6b56"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M553.71 437.23a36.16 36.16 0 0 1-36.16 36.16 34.9 34.9 0 0 1-6.18-.54 35.78 35.78 0 0 1-3.69-15.9 36.17 36.17 0 0 1 36.17-36.16 35 35 0 0 1 6.18.54 35.78 35.78 0 0 1 3.68 15.9Z"
      fill="#fcc690"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M575.08 547.37a42.71 42.71 0 0 1-3.18 16.22 45.6 45.6 0 0 1-73.65-36 44.24 44.24 0 0 1 .49-6.66 42.73 42.73 0 0 1 76.34 26.39Z"
      fill="#eb6b56"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M696.83 465.8a36.17 36.17 0 1 1-48-44.11Z"
      fill="#b27f5d"
      transform="translate(-273.43 -417.5)"
    />
    <path
      d="M698.36 454.34a36.07 36.07 0 0 1-1.09 9.87 1 1 0 0 1-1.65.47l-45.56-41.88a1 1 0 0 1 .33-1.68 36.17 36.17 0 0 1 48 33.22Z"
      fill="#474747"
      transform="translate(-273.43 -417.5)"
    />
  </svg>
);

export default Logo;
